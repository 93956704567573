const getApi = () => import('@/services/api')

const state = {
  active: false,
  email: null,
  credits: 0,
  order: null,
  phones: [],
  showSwitchere: false,
  showBtcpay: false,
  showCreditCards: false,
  promoMessage: ''
}

const getters = {
  isActive ({ active }) {
    return active
  },
  getInfo (state) {
    return state
  },
  getCredits ({ credits }) {
    return credits
  },
  getOrder ({ order }) {
    return order
  },
  getPhones ({ phones }) {
    return phones
  },
  getShowSwitchere ({ showSwitchere }) {
    return showSwitchere
  },
  getShowBtcpay ({ showBtcpay }) {
    return showBtcpay
  },
  getShowCreditCards ({ showCreditCards }) {
    return showCreditCards
  },
  getPromoMessage ({ promoMessage }) {
    return promoMessage
  }
}

const mutations = {
  setInfo (state, { email, credits = 0, active = false, order, phones, showSwitchere = false, showBtcpay = false, showCreditCards = false, promoMessage }) {
    state.email = email
    state.credits = credits
    state.active = active
    state.order = order
    state.phones = phones
    state.showSwitchere = showSwitchere
    state.showBtcpay = showBtcpay
    state.showCreditCards = showCreditCards
    state.promoMessage = promoMessage
  }
}

const actions = {
  async fetchInfo ({ commit, getters: { getAuth: auth } }) {
    const api = await getApi()
    const { message, ...info } = await api.getUserInfo(auth)
    commit('setInfo', info)
    if (message) {
      commit('setAdminMessage', { message })
    }
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
